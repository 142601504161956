export const GET_USER_ACTION = 'GET_USER_ACTION';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const getUserAction = ({ id }) => ({
  type: GET_USER_ACTION,

  id,
});

export const SET_USER_VIBE_ACTION = 'SET_USER_VIBE_ACTION';
export const SET_USER_VIBE_START = 'SET_USER_VIBE_START';
export const SET_USER_VIBE_SUCCESS = 'SET_USER_VIBE_SUCCESS';
export const SET_USER_VIBE_FAIL = 'SET_USER_VIBE_FAIL';

export const setUserVibeAction = ({ id, vibe }) => ({
  type: SET_USER_VIBE_ACTION,

  id,
  vibe,
});