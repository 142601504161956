import {
  STATUS_START,
  STATUS_SUCCESS,
  STATUS_FAIL,
} from 'store/action';
import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  SET_USER_VIBE_SUCCESS,
} from './action';
  
const userInitialState = {};
const userReducer = (state = userInitialState, action) => {
  switch(action.type) {
    case GET_USER_START:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: STATUS_START,
        },
      };
    case GET_USER_SUCCESS:
    case SET_USER_VIBE_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: STATUS_SUCCESS,
          user: action.user,
        },
      };
    case GET_USER_FAIL:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: STATUS_FAIL,
          reason: action.reason,
        },
      };
    default:
      return state;
  };
};

const userVibeCacheInitialState = {};
const userVibeCacheReducer = (state = userVibeCacheInitialState, action) => {
  switch(action.type) {
    case GET_USER_SUCCESS:
      if (state[action.id]) { return state; }
      return {
        ...state,
        [action.id]: [],
      };
    case SET_USER_VIBE_SUCCESS:
      return {
        ...state,
        [action.id]: [...state[action.id], action.vibe],
      };
    default:
      return state;
  };
};

export { userReducer, userVibeCacheReducer };