/**
 * Combine all reducers in this file and export the combined reducers.
 */

 import { combineReducers } from '@reduxjs/toolkit';

 import { connectRouter } from 'connected-react-router';
 import { history } from 'utils/history';
 
 import { userReducer, userVibeCacheReducer } from 'store/user/reducer';
import { animationReducer } from 'store/animation/reducer';

 /**
  * Merges the main reducer with the router state and dynamically injected reducers
  */
 export function createReducer() {
   const rootReducer = combineReducers({
     user: userReducer,
     userVibeCache: userVibeCacheReducer,

     animation: animationReducer,
 
     router: connectRouter(history),
   });
 
   return rootReducer;
 }