import React from 'react';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import { reselectUser, reselectUserVibeCache } from 'store/user/selector';
import { useDispatch, useSelector } from 'react-redux';
import { setUserVibeAction } from 'store/user/action';

import firebase from 'firebase/app';

const VIBE_ENUM = [
  {
    key: "collectedVibes.chill",
    label: "😎 Chill",
    analytic: "webCollectVibeChill_button",
  },
  {
    key: "collectedVibes.hot",
    label: "🔥 Hot",
    analytic: "webCollectVibeHot_button",
  },
  {
    key: "collectedVibes.pretty",
    label: "😍 Pretty",
    analytic: "webCollectVibePretty_button",
  },
  {
    key: "collectedVibes.kind",
    label: "😋 Kind",
    analytic: "webCollectVibeKind_button",
  },
  {
    key: "collectedVibes.goals",
    label: "🙌 Goals",
    analytic: "webCollectVibeGoals_button",
  },
  {
    key: "collectedVibes.thicc",
    label: "🍑 Thicc",
    analytic: "webCollectVibeThicc_button",
  },
  {
    key: "collectedVibes.cute",
    label: "😇 Cute",
    analytic: "webCollectVibeCute_button",
  },
  {
    key: "collectedVibes.funny",
    label: "😂 Funny",
    analytic: "webCollectVibeFunny_button",
  },
  {
    key: "collectedVibes.honest",
    label: "🤝 Honest",
    analytic: "webCollectVibeHonest_button",
  },
  {
    key: "collectedVibes.beautiful",
    label: "😍 Beautiful",
    analytic: "webCollectVibeBeautiful_button",
  },
];

export default function UserVibe({ id }) {
  const { user } = useSelector(state => reselectUser(state, id));
  const userVibeCache = useSelector(state => reselectUserVibeCache(state, id));
  const dispatch = useDispatch();

  const vibes = VIBE_ENUM.map(({ key, label, analytic }) => {
    const userVibe = (user.collectedVibes &&
      user.collectedVibes.find(({ name }) => name === key)) || {
        name: key,
        count: 0,
      };
    let vibeCount = userVibe.count;
    const hasAlreadyVoted = userVibeCache.includes(key);

    let clickElement;
    if (vibeCount) {
      clickElement = (
        <div className={`user-vibe--list--item--click user-vibe--list--item--click-count ${(hasAlreadyVoted && 'selected') || ''}`}>
          <p className={`${vibeCount > 99 ? 's5' : 's4'} bold`}>{vibeCount > 99 ? '99+' : vibeCount}</p>
        </div>
      );
    } else {
      clickElement = (
        <div className="user-vibe--list--item--click user-vibe--list--item--click-plus">
          <PlusIcon />
        </div>
      );
    }

    const onClickVibe = () => {
      if (!hasAlreadyVoted) {
        dispatch(setUserVibeAction({ id, vibe: key }));
        console.log(`sending ${analytic} event`, {
          screen: 'webCollectedVibe',
          toUserId: id,
        });
        firebase.analytics().logEvent(analytic, {
          screen: 'webCollectedVibe',
          toUserId: id,
        });
      }
    };

    return (
      <div
        key={key}
        className={`user-vibe--list--item ${(hasAlreadyVoted && 'selected') || ''}`}
        onClick={onClickVibe}
      >
        <p className="s2 semimedium">{label}</p>
        {clickElement}
      </div>
    );
  })

  return (
    <div className="user-vibe">
      <h2 className="user-vibe--text m5 bold">What's my vibe?</h2>
      <div className="user-vibe--list">
        {vibes}
      </div>
    </div>
  );
}