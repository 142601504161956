import React, { useEffect, useState } from 'react';

import vibeLogo from 'images/vibe.png';

import { reselectUser } from 'store/user/selector';
import { useSelector } from 'react-redux';

import { preloadImages } from 'utils/image';

const LINEAR_GRADIENT = 'linear-gradient(180.02deg, rgba(0, 0, 0, 0.2) 12.71%, rgba(0, 0, 0, 0) 25.96%, rgba(0, 0, 0, 0.08) 72.01%, rgba(0, 0, 0, 0.4) 87.55%)';

export default function UserProfile({ id }) {
  const { user } = useSelector(state => reselectUser(state, id));
  const [pictureIndex, setPictureIndex] = useState(0);

  useEffect(() => {
    preloadImages(user.profilePictures);
  }, [user]);

  const todayS = Math.round(new Date() / 1000);
  const ageS = todayS - user.birthdate._seconds;
  const age = Math.floor(ageS / (60 * 60 * 24 * 365));

  const currentPicture = user.profilePictures.find((picture) => picture.index === pictureIndex);

  const changePictureIndex = (modifier) => {
    const newPictureIndex = (user.profilePictures.length + pictureIndex + modifier) % user.profilePictures.length;

    setPictureIndex(newPictureIndex);
  };

  return (
    <div className="user-profile">
      <div className="user-profile--gallery" style={{
        backgroundImage: `${LINEAR_GRADIENT}, url(${currentPicture.imagePath})`,
      }}>
        <div
          className="user-profile--gallery--progress"
          style={{
            gridTemplateColumns: `repeat(${user.profilePictures.length}, 1fr)`
          }}
        >
          {
            user.profilePictures.length > 1 && user.profilePictures.map((_, index) => {
              return (
                <div
                  className={`user-profile--gallery--progress--item ${(index === pictureIndex && 'selected') || ''}`}
                  key={index}
                ></div>
              );
            })
          }
        </div>
        <div className="user-profile--gallery--info">
          <p className="m1 semibold">{user.name}</p>
          <p className="m3">{age}</p>
        </div>

        <div
          className="user-profile--gallery--go-left"
          onClick={() => changePictureIndex(-1)}
        ></div>
        <div
          className="user-profile--gallery--go-right"
          onClick={() => changePictureIndex(1)}
        ></div>

        <div className="user-profile--gallery--logo">
          <img src={vibeLogo} alt="vibe-logo" />
        </div>

      </div>
    </div>
  );
}