import { createSelector } from '@reduxjs/toolkit';

const userDomain = state => state.user;

export const reselectUser = createSelector(
    userDomain,
    (_, id) => id,
    (userState, id) => userState[id]
);

const userVibeCacheDomain = state => state.userVibeCache;

export const reselectUserVibeCache = createSelector(
    userVibeCacheDomain,
    (_, id) => id,
    (userVibeCacheState, id) => userVibeCacheState[id]
);