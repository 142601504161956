import './styles/index.scss';

import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import {
  Route,
  Switch,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Home from 'pages/home';
import CollectVibe from 'pages/collectVibe';

import './firebase';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from 'store';
import { history } from 'utils/history';

const { store, persistor } = createStore(history);

export default function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/collect/:id">
                <CollectVibe />
              </Route>
            </Switch>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}