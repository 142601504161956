import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import UserProfile from 'component/userProfile';

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { getUserAction } from 'store/user/action';
import { reselectUser } from 'store/user/selector';
import { STATUS_FAIL, STATUS_START, STATUS_SUCCESS } from 'store/action';
import RedirectButton from './redirectButton';
import UserVibe from 'component/userVibe';
import AnimationFirework from 'component/animation/firework';

import firebase from 'firebase/app';

export default function CollectVibe() {
  let { id } = useParams();
  let dispatch = useDispatch();
  const user = useSelector(state => reselectUser(state, id));

  useEffect(() => {
    console.log('sending webCollectVibe_screen event');
    firebase.analytics().logEvent('webCollectVibe_screen');
  }, []);

  useEffect(() => {
    dispatch(getUserAction({ id }));
  }, [id]);

  return (
    <div className="collect-vibe">
      <Helmet>
        <title>VIBE - Blow up your snapchat</title>
      </Helmet>
      {user && user.status === STATUS_SUCCESS && ([
        <UserProfile id={id} key="1" />,
        <UserVibe id={id} key="2" />,
        <div className="collect-vibe__sticky-redirect" key="3">
          <RedirectButton />
        </div>,
      ])}
      {(!user || user.status === STATUS_START) && (
        <div className="loader">
          <div className="loader__square">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      )}
      {(user && user.status === STATUS_FAIL) && (
        <div className="error">
          <p className="m5 bold">User not found.</p>
        </div>
      )}
        <AnimationFirework />
    </div>
  );
}