import React from 'react';

import { ReactComponent as SnapIcon } from 'icons/snap.svg';

import firebase from 'firebase/app';

export default function RedirectButton() {
  const onRedirect = () => {
    console.log('sending webCollectVibeDownload_button event');
    firebase.analytics().logEvent('webCollectVibeDownload_button');

    window.location.href = 'https://onvi.be/u/collectVibes';
  };

  return (
    <div
      className="redirect-button link"
      onClick={onRedirect}
    >
      <p className="m5 semibold">Collect good vibes</p>
      <SnapIcon />
    </div>
  );
}