import firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";

const firebaseConfigProd = {
  apiKey: "AIzaSyCyf1PShgHfKI7esaMa66OuRM_cw9lXywU",
  authDomain: "rose-prod.firebaseapp.com",
  databaseURL: "https://rose-prod.firebaseio.com",
  projectId: "rose-prod",
  storageBucket: "rose-prod.appspot.com",
  messagingSenderId: "1076174849016",
  appId: "1:1076174849016:web:ea270690a191a34be18c0d",
  measurementId: "G-S7541PZ47W",
};

const firebaseConfigStaging = {
  apiKey: "AIzaSyCXDMRxi9kqgVJ864pPl0Y9EUr0JgV8nX4",
  authDomain: "rose-staging.firebaseapp.com",
  databaseURL: "https://rose-staging.firebaseio.com",
  projectId: "rose-staging",
  storageBucket: "rose-staging.appspot.com",
  messagingSenderId: "997638501955",
  appId: "1:997638501955:web:63b0321fe2ac25215a86f6",
  measurementId: "G-YK4F6TRCCY",
};

firebase.initializeApp(
  process.env.REACT_APP_FIREBASE_PROJECT === "rose-prod"
    ? firebaseConfigProd
    : firebaseConfigStaging
);
firebase.analytics();
