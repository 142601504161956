import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isCompositeComponent } from 'react-dom/test-utils';

import { useSelector } from 'react-redux';
import { reselectFireworkAnimation } from 'store/animation/selector';

const VIBE_EMOJI_ENUM = {
  'collectedVibes.chill': '😎',
  'collectedVibes.hot': '🔥',
  'collectedVibes.pretty': '😍',
  'collectedVibes.kind': '😋',
  'collectedVibes.goals': '🙌',
  'collectedVibes.thicc': '🍑',
  'collectedVibes.cute': '😇',
  'collectedVibes.funny': '😂',
  'collectedVibes.honest': '🤝',
  'collectedVibes.beautiful': '😍',
};

const fakeSin = (x) => {
  x = x % 4;

  const B = 4 / Math.PI;
  const C = -4 / (Math.PI * Math.PI);

  return -(B * x + C * x * Math.abs(x));
}

class FireworkData {
  constructor(vibe, canvasSize) {
    this.vibe = vibe;
    this.emoji = VIBE_EMOJI_ENUM[vibe];
    this.items = [];
    this.startTimer = (new Date()).getTime();

    for (let i = 0; i < 30; i++) {
      const y = this._r(-1500, -10);

      const x = this._r(-10, canvasSize.width + 10);

      this.items.push({
        x,
        y,
        size: this._r(20, 40) * canvasSize.ratio,
        rotation: this._r(0, 360),
        speed: this._r(5, 10),
      });
    }
  }

  _r(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}

const renderFirework = async (fireworkData, ctx, canvasHeight) => {
  const deltaTimer = (new Date()).getTime() - fireworkData.startTimer;

  fireworkData.items = fireworkData.items.filter(item => {
    const x = Math.floor(fakeSin(deltaTimer / 100) * (item.speed / 10) + item.x);
    const y = Math.floor((deltaTimer / item.speed * 6) + item.y);

    // Apply canvas for rotation 
    ctx.translate(x, y);
    ctx.rotate((deltaTimer / 50 / item.speed) + item.rotation * (Math.PI / 180));

    ctx.textAlign = "center";
    ctx.font = `${item.size}px serif`;
    ctx.fillText(fireworkData.emoji, 0, item.size / 2);

    // Optimized restore canvas 
    ctx.rotate(-((deltaTimer / 50 / item.speed) + item.rotation * (Math.PI / 180)));
    ctx.translate(-x, -y);

    if (y > canvasHeight + 20) {
      return false;
    }
    return true;
  });
}

export default function AnimationFirework() {
  const fireworkAnimation = useSelector(reselectFireworkAnimation);

  const refCanvas = useRef(null);
  const requestAnimationFrameRef = useRef(null);
  const refContainer = useRef(null);
  const [canvasSize, setCanvasSize] = useState({
    width: 1,
    height: 1,
  });
  const [canvasRatio, setCanvasRatio] = useState(1);

  const [animationRunning, setAnimationRunning] = useState([]);

  const [startTimer] = useState((new Date()).getTime());
  const [frame, setFrame] = useState(0);

  // Adding a new animation to the queue
  useEffect(() => {
    if (fireworkAnimation) {
      fireworkAnimation.forEach(vibe => {
        const exists = animationRunning.find(item => item.vibe === vibe);
        if (!exists) {
          setAnimationRunning([
            ...animationRunning,
            new FireworkData(vibe, {
              width: canvasSize.width * canvasRatio,
              height: canvasSize.height * canvasRatio,
              ratio: canvasRatio,
            })
          ]);
        }
      });
    }
  }, [fireworkAnimation, animationRunning, canvasSize, canvasRatio]);

  const renderFrame = useCallback(() => {
    // Optimise framerate
    const deltaTimer = (new Date()).getTime() - startTimer;
    const currentFrame = deltaTimer % (1000 / 60);
    if (currentFrame === frame) { return; }
    setFrame(currentFrame);

    // Run animation for current frame
    const canvas = refCanvas.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvasSize.width * canvasRatio, canvasSize.height * canvasRatio);
    animationRunning.forEach(fireworkData => {
      renderFirework(fireworkData, ctx, canvasSize.height * canvasRatio);
    });
  }, [animationRunning, canvasSize, canvasRatio, startTimer, frame]);

  const tick = useCallback(() => {
    if (!refCanvas.current) return;
    renderFrame();
    requestAnimationFrameRef.current = requestAnimationFrame(tick);
  }, [refCanvas, renderFrame]);

  useEffect(() => {
    requestAnimationFrameRef.current = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(requestAnimationFrameRef.current);
    };
  }, [tick]);

  // Optimise canvas rendering blur
  useEffect(() => {
    function handleResize() {
      const containerReact = refContainer.current.getBoundingClientRect();
      setCanvasSize({
        width: containerReact.width,
        height: containerReact.height,
      });

      if (refCanvas.current) {
        const ctx = refCanvas.current.getContext('2d');  
        const dpr = window.devicePixelRatio || 1;
        const bsr = ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio || 1;
        const ratio = dpr / bsr;

        setCanvasRatio(ratio);
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div ref={refContainer} className="animation-canvas">
      <canvas
        ref={refCanvas}
        height={canvasSize.height * canvasRatio}
        width={canvasSize.width * canvasRatio}
        style={canvasSize}
      />
    </div>
  );
}