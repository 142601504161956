import { call, cancelled } from 'redux-saga/effects';

import axios from 'axios';

import qs from 'qs';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost/';

// eslint-disable-next-line require-yield
function* getBaseHeaders() {
  const headers = {
    'Content-Type': 'application/json',
  };

  return headers;
}

export function request(config = {}) {
  return call(function* () {
    const source = axios.CancelToken.source();

    config.baseURL = config.baseURL || API_URL;
    config.headers = config.headers || {};
    const baseHeaders = yield call(getBaseHeaders);
    config.headers = {
      ...config.headers,
      ...baseHeaders,
    };

    try {
      const res = yield call(axios.request, {
        cancelToken: source.token,
        ...config,
        paramsSerializer: params => {
          return qs.stringify(params, { indices: false });
        },
      });

      if (res.status >= 200 && res.status < 300) {
        return res;
      }

      throw new Error(res?.data);
    } catch (err) {
      throw err;
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}

/*
  Note:
  An additional parameter to axios can be passed to the config object.
  - `externalApi`: true if a call is done to the externalApi which will disable
    the default set of the authorization token. default: false
*/

export function getApi(url, data, config = {}) {
  return request({ method: 'get', url, params: data, ...config });
}

export function postApi(url, data, config = {}) {
  return request({ method: 'post', url, data, ...config });
}

export function putApi(url, data, config = {}) {
  return request({ method: 'put', url, data, ...config });
}

export function delApi(url, data, config = {}) {
  return request({ method: 'delete', url, data, ...config });
}

export function patchApi(url, data, config = {}) {
  return request({ method: 'patch', url, data, ...config });
}