import {
  FINISH_FIREWORK_ANIMATION_ACTION,
} from './action';
import {
  SET_USER_VIBE_SUCCESS,
} from 'store/user/action';
  
const animationInitialState = {
  firework: [],
};
const animationReducer = (state = animationInitialState, action) => {
  switch(action.type) {
    case SET_USER_VIBE_SUCCESS:
      return {
        ...state,
        firework: [...state.firework, action.vibe],
      };
    case FINISH_FIREWORK_ANIMATION_ACTION:
      return {
        ...state,
        firework: state.firework.filter(vibe => vibe !== action.vibe),
      };
    default:
      return state;
  };
};

export { animationReducer };