import React, { useEffect } from 'react';
import BgVideo from 'component/bgVideo';

import { ReactComponent as VibeLogo } from 'icons/vibe.svg';
import { ReactComponent as AppStore } from 'icons/download-appstore.svg';
import GooglePlay from 'icons/google-play.png';

import videoHorizontalSrc from 'video/horizontal.mp4';
import videoVerticalSrc from 'video/vertical.mp4';

import firebase from 'firebase/app';

export default function Home() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let isOSx = /iPad|iPhone|iPod/.test(userAgent);
  let isAndroid = /android/i.test(userAgent);

  // In case we don't know where the request come from we do prefer to show every button
  if (!isOSx && !isAndroid) {
    isOSx = true;
    isAndroid = true;
  }

  useEffect(() => {
    try {
      if (isOSx) {
        console.log('Tenjin impression OSx');
        fetch('https://track.tenjin.com/v0/impression/hxVWg1Hf5MoKo4JPycOBxg?');
      }
      if (isAndroid) {
        console.log('Tenjin impression Android');
        fetch('https://track.tenjin.com/v0/impression/fePX4GEU6GxiCDDMZU6uRZ?');
      }
    } catch (err) {
      console.error(err);
    }
    console.log('sending web_vibe_screen event');
    firebase.analytics().logEvent('web_vibe_screen', {
      screen: ['home']
    });
  }, []);

  const onRedirectAppstore = () => {
    console.log('sending web_vibe_appstore_button event');
    firebase.analytics().logEvent('web_vibe_appstore_button', {
      screen: ['home'],
    });

    window.location.href = 'https://track.tenjin.com/v0/click/hxVWg1Hf5MoKo4JPycOBxg?';
  };

  const onRedirectGoogleplay = () => {
    console.log('sending web_vibe_googleplay_button event');
    firebase.analytics().logEvent('web_vibe_googleplay_button', {
      screen: ['home'],
    });

    window.location.href = 'https://track.tenjin.com/v0/click/fePX4GEU6GxiCDDMZU6uRZ?';
  };

  return (
    <div className="home">
      <div className="home__header">
        <VibeLogo />
      </div>
      <div className="home__footer">
        <div className="mq-nl home__footer__store">
          {isOSx && (
            <div className="appstore a" onClick={onRedirectAppstore}>
              <AppStore />
            </div>
          )}
          {isAndroid && (
            <div className="googleplay a" onClick={onRedirectGoogleplay}>
              <img src={GooglePlay} alt="google playstore" />
            </div>
          )}
          </div>
        <div className="home__footer__nav">
          <div className="white home__footer__nav__links">
            <a href="https://www.notion.so/takeofflabs/Privacy-553e29f69d514486afffaa06ca378bd3">
              <span>Privacy</span>
            </a>
            <a href="https://www.notion.so/takeofflabs/Terms-2235aa91831c4043b672a0e8d6df13b6">
              <span className="mq-nt mq-nl">T & C</span>
              <span className="mq-nm">Terms & Conditions</span>
            </a>
            <a href="mailto:contact@takeoff-labs.com">
              <span>Contact</span>
            </a>
          </div>
          <div className="mq-nt mq-nm home__footer__nav__store">
            <div className="appstore a" onClick={onRedirectAppstore}>
              <AppStore />
            </div>
            <div className="googleplay a" onClick={onRedirectGoogleplay}>
              <img src={GooglePlay} alt="google playstore" />
            </div>
          </div>
        </div>
      </div>
      <BgVideo src={(window.outerWidth > 768 && videoHorizontalSrc) || videoVerticalSrc} />
    </div>
  );
}
