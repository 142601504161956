import { configureStore } from '@reduxjs/toolkit';

import { createReducer } from './reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootSaga from './sagas';

const persistConfig = {
  key: 'vibe-web',
  storage,
  whitelist: ['userVibeCache'],
};

const persistedReducer = persistReducer(persistConfig, createReducer());

const sagaMiddleware = createSagaMiddleware();

export default function createStore(history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware];

  if (history) {
    middlewares.push(routerMiddleware(history));
  }

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [...middlewares],
    devTools:
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // This fetch the new state of the above reducers.
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return { store, persistor };
};