import { put, takeEvery, takeLeading } from 'redux-saga/effects';

import { getApi, postApi } from 'store/baseApi';

import {
  GET_USER_ACTION,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  SET_USER_VIBE_ACTION,
  SET_USER_VIBE_START,
  SET_USER_VIBE_SUCCESS,
  SET_USER_VIBE_FAIL,
} from './action';

function* getUserSaga({ id }) {
  yield put({
    type: GET_USER_START,
    id,
  });

  try {
    const res = yield getApi(`/users/${id}`);

    yield put({
      type: GET_USER_SUCCESS,
      id,
      user: res.data.data
    });
  } catch (err) {
    yield put({
      type: GET_USER_FAIL,
      reason: err.message,
      id,
    });
  }
}

function* setUserVibe({ id, vibe }) {
  yield put({
    type: SET_USER_VIBE_START,
    id,
    vibe,
  });

  try {
    const res = yield postApi(`users/collectedVibes/${id}`, {
      name: vibe,
    })

    yield put({
      type: SET_USER_VIBE_SUCCESS,
      id,
      user: res.data.data,
      vibe,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: SET_USER_VIBE_FAIL,
      reason: err.message,
      id,
      vibe,
    });
  }
}

function* userSaga() {
  yield takeLeading(GET_USER_ACTION, getUserSaga);
  yield takeEvery(SET_USER_VIBE_ACTION, setUserVibe);
}

export default userSaga;